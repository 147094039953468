.cart-item {
  display: flex;
  /* flex-flow: row wrap; */
  align-items: center;
  justify-content: space-between;
  gap: 0.75rem;
  margin-bottom: 1rem;
  width: max(350px, 80%);
  margin-inline: auto;
  background-color: #f7f7f7;
  border-radius: 10px;
  padding: 1rem; 
  font-size: 1rem;
}
.cart-item aside img {
  border-radius: 10px;
}
.cart-item aside div > * {
  padding: 0.05rem 0;
  text-transform: capitalize;
}

.truck-name {
  font-weight: 600;
  font-size: 1.5rem;
}
.truck-capacity {
  color: var(--primary);
  padding: 1rem;
  border-radius: 5.5px;
}
.lower-section {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
}
.num-of-trips {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.num-of-trips input {
  font-size: 1rem;
  text-align: center;
  width: 20px;
  border: none;
  outline: none;
}
.price {
  align-self: flex-start;
  color: var(--primary);
  font-size: 1.2rem;
}
