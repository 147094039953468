.container {
  background-color: var(--white);
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 1.5rem;
  align-content: center;
  align-items: center;
  /* border: 1px solid red; */
  width: 100vw;
  color: var(--black);
}

.back-buton {
  position: absolute;
  top: 7%;
  left: 7%;
}

.section {
  height: 94vh;
}
/* LEFT SIDE */

.left-section {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
}
.logo {
  margin-bottom: 2rem;
}
.title {
  margin-bottom: 2rem;
}
.form {
  width: 60%;
}
.signup-form {
  width: 80%;
}

/* RIGHT SIDE */

.right-section {
  background-color: var(--primary);
  border-radius: 20px;
  /* overflow: hidden; */
  position: relative;
  height: 70vh;
  width: 90%;
}

.image {
  position: absolute;
  border-radius: 20px;
}
.right-img-1 {
  top: -10%;
  left: -10%;
}
.right-img-2 {
  top: 40%;
  right: -5%;
}
.right-img-3 {
  bottom: -5%;
  left: -5%;
}

/* EMAIL MESSAGE */

.message-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffffaa;
  backdrop-filter: blur(10px);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cancel {
  position: absolute;
  top: 10px;
  right: 10px;
}
.cancel:hover {
  cursor: pointer;
}
.message-content {
  position: relative;
  background-color: var(--white);
  border-radius: 15px;
  padding: 3rem 5rem;
  /* display: flex; */
  text-align: center;
  box-shadow: 0 4px 20px #00000022;
  color: var(--black);
}
.message-content h3 {
  margin-top: 2rem;
}
.message-content h4 {
  margin-bottom: 1rem;
  color: var(--primary);
}
@media (max-width: 767px) {
  .right-section {
    display: none;
  }
  .form {
    width: 80%;
  }
  .signup-form {
    width: 80%;
  }
  .container {
    grid-template-columns: 1fr;
    align-content: start;
    align-items: start;
  }
}

.form-footer-button{
  margin: 0;
  padding: 0;
}