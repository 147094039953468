.slide {
  /* height: 94vh; */
  width: 100%;
  height: 70vh;
  border-radius: 20px;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: var(--primary);
  background-position: center center;
  position: relative;
}
.slide-1 {
  background: -webkit-linear-gradient(#096dd9cc, #096dd9cc),
    url("../../assets/images/main-bg12.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.slide-2 {
  background: -webkit-linear-gradient(#096dd9cc, #096dd9cc),
    url("../../assets/images/main-bg11.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.slide-3 {
  background: -webkit-linear-gradient(#096dd9cc, #096dd9cc),
    url("../../assets/images/main-bg6.jpg");
  background-repeat: no-repeat;
  background-size: cover;
}
.slide-4 {
  background: -webkit-linear-gradient(#096dd9cc, #096dd9cc),
    url("../../assets/images/main-bg4.jpg");
}
.slide-5 {
  background: -webkit-linear-gradient(#096dd9cc, #096dd9cc),
    url("../../assets/images/main-bg5.png");
}
/* .slide-6 {
  background: -webkit-linear-gradient(#096dd9cc, #096dd9cc),
    url("../../assets/images/main-bg6.png");
} */
.slider-text {
  position: absolute;
  top: 40%;
  left: 10%;
  width: 60%;
  font-size: 1.8rem;
  font-weight: 600;
  line-height: 1.2;
  color: var(--white);
}
.header-overlay {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #044a9699;
  width: 100%;
  height: 95vh;
  /* z-index: 5; */
}
.image {
  position: absolute;
  border-radius: 20px;
  box-shadow: 0 0px 100px #096dd999;
}
.right-img-1 {
  top: -10%;
  left: -10%;
}
.right-img-2 {
  top: 40%;
  right: -5%;
}
.right-img-3 {
  bottom: -5%;
  left: -5%;
}
