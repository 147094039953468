.main-content {
  margin-top: 2rem;
}
.card {
  border-left: 2px solid var(--primary);
  background-color: var(--white);
  min-width: 300px;
  width: 70%;
  /* // height: 10px; */
  padding: 2rem;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  /* align-items: center; */
  gap: 1rem;
  box-shadow: 0 4px 10px #00000022;
  margin-inline: auto;
  margin-bottom: 1.2rem;
}
.card-info {
  width: 100%;
  display: flex;
  flex-flow: column nowrap;
  gap: 1rem;
  justify-content: space-between;
  text-transform: capitalize;
}

.email{
 text-transform: lowercase; 
}

.card div {
  display: flex;
  justify-content: space-between;
}
.card  img {
  border-radius: 15px;
}

/* BUSINESS PROFILE */

.profile-content {
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
}

@media (max-width: 767px){
  .card div {
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-start;
    align-items: flex-start;

  }
}