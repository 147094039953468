.message-container {
  position: absolute;
  top: 0;
  left: 0;
  background-color: #ffffffaa;
  backdrop-filter: blur(10px);
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.message-content {
  background-color: var(--white);
  border-radius: 15px;
  padding: 3rem 5rem;
  /* display: flex; */
  text-align: center;
  box-shadow: 0 4px 20px #00000022;
  color: var(--black);
}
.message-content h3 {
  margin-top: 2rem;
}
.message-content h4 {
  margin-bottom: 1rem;
  color: var(--primary);
}

/* DIVER INFO */
.option-btn{
  border: none;
  outline: transparent;
  display: block;
  margin-top: 1rem;
  width: 100%;
  border-radius: 15px;
  border: 1px solid var(--primary);
  padding: 0.5rem;
  background: transparent;
}
.option-btn:hover {
  transition: 0.3s;
  background: #f7f7f7;
  /* opacity: 0.8; */
  transform: scale(1.025);
  cursor: pointer;
}
.option-btn h3 {
  color: var(--primary);
  font-size: 1.2rem;
  font-weight: 700;
}