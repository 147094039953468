.contianer {
  height: 100vh;
  width: 100%;
  display: grid;
  place-items: center;
  position: relative;
}
.logo {
  /* position: absolute; */
  /* top: 50%;
  left: 50%;
  transform: translate(-50%); */
  animation: animte_logo 1s ease-in-out normal  infinite forwards  ;
  /* width: 100%; */
}

@keyframes animte_logo {
  from, to{
    opacity: 1;
    transform: scale(1)
  }
  50%{
    opacity: 0.5;
    transform: scale(1.2)
  }
  
}