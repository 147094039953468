.container {
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: grid;
  place-items: center;
  place-content: center;
  text-align: center;
}

.content {
  background-color: #e9e9e9;
  padding: 3rem 5rem;
  width: 100%;
  text-align: center;
  margin-top: 3rem;
}
.content h1 {
  font-size: 2rem;
}
.content p {
  margin-top: 1.5rem;

}
.content a, .content a:visited {
  color: var(--primary);
}
.content .approved {
  color: green;
}
.content .not-approved {
 color: red;

}