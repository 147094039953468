.details-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1.2rem;
  font-size: 1rem;
}
.left-section {
  display: flex;
  flex-flow: column nowrap;
  gap: 1.2rem;
}
.service-locations {
  text-align: center;
  grid-column: 1 / 3;
}
.service-locations ul li {
  list-style-type: none;
  padding: 0.5rem 0;
  text-transform: capitalize;
  border-bottom: 1px solid #00000015;
}
.info-card {
  width: 100%;
  min-width: 250px;
  height: 100%;
  max-height: 60vh;
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 6px #00000011;
  /* border-top: 1px solid var(--primary); */
  background-color: var(--white);
  padding: 2rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.info-table {
  width: 100%;
  min-width: 250px;
  height: 100%;
  /* max-height: 60vh; */
  overflow: hidden;
  border-radius: 15px;
  box-shadow: 0 4px 6px #00000011;
  /* border-top: 1px solid var(--primary); */
  background-color: var(--white);
  padding: 2rem;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
.info-card img {
  border-radius: 10px;
}
.info-card .title {
  text-align: center;
}
.info-content {
  width: 100%;
  text-align: center;
}
.truck-image img {
  max-height: 300px;
}
.set-availability {
  padding-top: 0.5rem;
  width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.driver-btn {
  width: 300px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
}
.info-entry {
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  text-transform: capitalize;
  justify-content: space-between;
}

@media (max-width: 767px) {
  .info-entry {
    flex-flow: column nowrap;
    text-align: left;
  }
  .details-container {
    grid-template-columns: 1fr;
    border: 1px solid red;
  }
}
