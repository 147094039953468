@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@200;300;400;500;600;700;800&display=swap");
/* @import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap'); */

:root {
  /* --primary: #007bff; */
  --primary: #096dd9;
  --primaryAccent: rgb(231, 242, 247);
  --white: #ffffff;
  --secondary: #fd7e14;
  --black: #000021;
}
.ant-menu-item-selected {
  background-color: var(--primaryAccent) !important;
}
* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-weight: 300;
}
body {
  /* font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif; */
  font-family: "Montserrat", sans-serif;
  /* font-family: 'Inter', sans-serif; */
  font-weight: 400;
  scroll-behavior: smooth;
}
