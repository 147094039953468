.truck-card {
  /* height: 400px; */
  /* height: auto; */
  /* width: 100%; */
  min-width: 250px;
  border-radius: 10px;
  box-shadow: 0 4px 25px var(--primaryAccent);
  border: 1px solid #096dd922;
  /* background-color: var(--white); */
  display: flex;
  flex-flow: column nowrap;
  /* align-items: center; */
  gap: 0rem;
  overflow: hidden;
  font-size: 1rem;
}
.truck-card > *:not(:first-child) {
  padding: 0.75rem 1rem;
}
.truck-card .image {
  height: 230px;
  width: 100%;
  /* border: 1px solid red; */
  overflow: hidden;
  display: flex;
  place-items: center;
  margin-top: 1rem;
  border-radius: 15px;
  
}
.truck-card .image img {
  width: 100%;
}

.truck-info {
  display: flex;
  flex-direction: column;
  gap: 0.35rem;
  text-transform: capitalize;
}
.top-bar {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.top-bar h3 {
  color: var(--primary);
  font-size: 1.5rem;
  padding: 0;
  margin: 0;
  padding-inline: 1rem;
  border-radius: 20px;
  background-color: var(--primaryAccent);
  display: grid;
  place-items: center;
}
.driver-info {
  padding: 1rem;
  border-radius: 15px;
  background-color: var(--primaryAccent);
  margin-top: 0.5rem;
  
}
.driver-info .content {
  margin-top: 1rem;
  display: flex;
  justify-content: center;
  gap: 1.2rem;
  align-items: center;
  font-size: 1rem;
  text-align: left;
}
.truck-model b {
  font-weight: 600;
}
.driver-info  h3 {
  text-align: center;
  color: var(--primary)
}
.driver-photo img {
  border-radius: 10px;
  overflow: hidden;
}
.truck-name {
  font-weight: 600;
  font-size: 1.3rem;
}
.truck-capacity {
  color: var(--primary);
  font-size: 1rem;
}
.truck-card sub {
  width: 100%;
  font-size: 1rem;
  /* text-align: right; */
}
.truck-card sub aside {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.truck-card sub h2 {
  font-size: 2rem;
  color: var(--primary);
}
.truck-card sub h3 {
  font-size: 1.5rem;
  color: var(--primary);
}
