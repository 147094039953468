/* :root {
  font-family: Inter, Avenir, Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 400;

  color-scheme: light dark;
  color: rgba(255, 255, 255, 0.87);
  background-color: #242424;

  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-text-size-adjust: 100%;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}
a:hover {
  color: #535bf2;
}

body {
  margin: 0;
  display: flex;
  place-items: center;
  min-width: 320px;
  min-height: 100vh;
}

h1 {
  font-size: 3.2em;
  line-height: 1.1;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  background-color: #1a1a1a;
  cursor: pointer;
  transition: border-color 0.25s;
}
button:hover {
  border-color: #646cff;
}
button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

@media (prefers-color-scheme: light) {
  :root {
    color: #213547;
    background-color: #ffffff;
  }
  a:hover {
    color: #747bff;
  }
  button {
    background-color: #f9f9f9;
  }
} */

.site-layout {
  margin:1rem;
}
@media (max-width:767px) {
  .site-layout{
    margin: 0.4rem 0.8rem;
  }
}

.c-center,
.c-end,
.c-space {
  width: 100%;
  display: flex;
}
.c-end {
  justify-content: flex-end;
}

.c-space {
  justify-content: space-between;
}
.c-center {
  justify-content: center;
}

.offline {
  text-align: center;
  height: 100vh;
  width: 100vw;
  display: grid;
  place-content: center;
}

/* TABLES */
.table-container {
  width: 100%;
  overflow-x: auto;
  /* border: 1px solid red; */
}
.table {
  min-width: 600px;
}
.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}

.c-space,
.c-end,
.c-center {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.c-end {
  justify-content: flex-end;
}
.c-space {
  justify-content: space-between;
}
.c-center {
  justify-content: center;
}

.offline {
  text-align: center;
  height: 100vh;
  align-items: center;
  font-size: 1.5rem;
}
.offline * {
  margin: 0 auto;
}
.offline small {
  opacity: 0.5;

}

.show-sm {
  display: none;
}
.show-lg {
  display: block;
}

@media (max-width: 767px) {
  .show-sm {
    display: block;
  }
  .show-lg {
    display: none;
  }
}