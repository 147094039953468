.top {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.top-info {
  background-color: #fffffff9;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: column; */
  align-items: center;
  gap: 1rem;
}
.top-info .top-info-amount {
  background-color: var(--primaryAccent);
  padding: 15px;
  border-radius: 10px;
  color: var(--primary);
  font-size: 1.3rem;
  /* margin-bottom: 1rem; */
}

.main-content {
  margin-top: 2rem;
  padding-bottom: 200px;
}
.card {
  border-left: 2px solid var(--primary);
  background-color: var(--white);
  min-width: 300px;
  width: 60%;
  /* // height: 10px; */
  padding: 1rem 1.2rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
  /* align-items: center; */
  gap: 1rem;
  box-shadow: 0 4px 10px #00000022;
  margin-inline: auto;
  margin-bottom: 1.2rem;
}

.card div {
  display: flex;
  justify-content: space-between;
}

.table-row:hover {
  opacity: 0.8;
  cursor: pointer;
}

/* BUSINESS PROFILE */

.profile-content {
  display: flex;
  justify-content: flex-end;
  flex-flow: row wrap;
}

/* DETAILS STYLES */

.upper-section {
  display: flex;
  gap: 1rem;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.upper-section aside {
  border-radius: 5px;
  width: max(350px, 47.5%);
  margin: 0 auto;
  box-shadow: 0 5px 20px #00000011;
  padding: 1rem 1.2rem;
  border-top: 1px solid var(--primary);
  font-size: 1rem;
}
.upper-section aside span {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  text-align: right;
  text-transform: capitalize;
}
.upper-section aside span b {
  text-align: left;
  margin-right: 1rem;
  font-weight: 600;
}

.details-main {
  padding-bottom: 2rem;
}
.details-main:hover {
  transition: 0.2s;
  opacity: 0.8;
  cursor: pointer;
}

@media (max-width: 767px) {
  .upper-section aside span {
    text-align: left;
    flex-direction: column;
    align-items: flex-start;
  }
}
