
.upper-section {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  /* align-items: flex-start; */
  flex-flow: row nowrap;
  gap:2rem;
  /* // margin: 0 auto; */
  /* // overflow-y: auto; */
  overflow-x: auto;
  padding-bottom: 1rem;
}
section.lower-section {
  width: 100%;
  /* // height: 400px; */
  display: flex;
  flex-flow: row nowrap;
  gap: 1.5rem;
  margin-top: 0.5rem;
  
}
section.lower-section aside {
  box-shadow: 0 4px 10px #00000022;
  border-radius: 5px;
  padding: 1rem;
  text-align: center;
  background-color: var(--white);
}
section.lower-section .lhs {
  width: 60%;
}
section.lower-section .rhs {
  width: 100%;
  height: 50vh;
  overflow-x: auto;
}
section.lower-section .rhs h3 {
  padding: 1rem 0;
}

section.lower-section .img {
  border-radius: 50%;
}

.admin-role {
  color: var(--primary);
}
.user-role {
  color: blue;
}
.unassigned {
  color: orange;
}

/*  */

.db-card {
  border-top: 2px solid var(--primary);
  background-color: var(--white);
  min-width: 320px;
  /* width: 350px; */
  /* // height: 10px; */
  padding: 0.6rem 1.5rem;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-around;
  /* justify-content: space-between;
  align-items: center; */
  /* gap: 1.2rem; */
  box-shadow: 0 4px 10px #00000022;
}
.db-card-top {
  margin-bottom: 1rem;
  flex:1;
}
.db-card-top h3 {
  font-size: 1.1rem;
  /* opacity: 0.5; */
  color: white;
  white-space: nowrap;
  padding: 0.3rem 0.6rem;
  border-radius: 7.5px;
  background-color: var(--primary);
  box-shadow: 0 4px 15px  #096dd922;
  /* margin-left: 1rem; */
}

.db-card h3.title {
  opacity: 0.7;
  font-size: 1rem;
  /* font-weight: 700; */
  margin-bottom: 1rem;
}
.db-card b {
  font-size: 1rem;
  /* background: var(--primary); */
  color: var(--primary);
  font-weight: 800;
  border-radius: 05px;
  padding: 0.3rem 0.6rem;
}

.db-card-info, .db-card-top  {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-top: 0.5rem; */
}

@media  (max-width:767px){
  .upper-section {
    gap:1rem;
  }
  .db-card {
    font-size: 0.9rem;
    padding: 0.4rem 1rem;
    min-width: 300px;
  }
  .db-card h3.title  {
    margin-bottom: 0.2rem;
  }
}
/* BUSINESS PROFILE STYLES */

/* TRUCK FLEET STYLES */
