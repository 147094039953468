.sider {
  color: var(--primary);
  background-color: white;
  position: sticky;
}
.logo {
  margin: 2rem auto 0;
  text-align: center;
}
.mobile-sider-toggle {  
  display: none;
  }

.toggle {
  font-size: 1.2rem;
  text-align: right;
  margin-right: 10%;
}
.dp {
  width: 90%;
  padding: 1rem;
  margin: 1rem auto;
  border-radius: 15px;
  /* background-color: var(--primaryAccent); */
  text-align: center;
  filter: grayscale(100%);
}
.dp .title {
  text-transform: capitalize;
}

.title h2 {
  opacity: 0.5;
}
.title p {
  font-weight: 600;
}
.logout {
  text-align: center;
  margin-top: 3rem;
}

.page-title {
  font-size: 1.5rem;
  font-weight: 600;
  border-radius: 15px;
  background-color: var(--primaryAccent);
  color: var(--primary);
  padding: 0.7rem 1.2rem;
  display: inline-block;
  /* margin-bottom: 0.2rem; */
}

header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* padding: 0 1rem; */
  margin-bottom: 1rem;
}
header h1 {
  text-transform: capitalize;
}

@media (max-width: 767px) {
  .sider-container {
    position: fixed !important;
    top:0;
    left:0;
    width:100%;
    z-index: 5;
    background-color: #ffffff55;
    backdrop-filter: blur(10px);
    height: 100vh;
    overflow: hidden;
  }
  .close {
    position: absolute;
    top: 20px;
    right: 10px;
    font-size: 2rem;
  }
  .sider {
    box-shadow: 5px 0 10px #00000011;
    animation: slide-in 0.3s;
  }
  .page-title, .greeting {
    font-size: 1rem;
  }

  @keyframes slide-in {
    from {
      transform: translateX(-200px);
    }
    to {
      transform: translateX(0px);
    }
  }
  .mobile-sider-toggle {
  display: block;
  }
}